var render = function () {
  var _vm$task, _vm$task$milestoneTas, _vm$task$milestoneTas2, _vm$task2, _vm$task3, _vm$task4, _vm$task5, _vm$task8, _vm$task8$assignee, _vm$task8$assignee$da, _vm$task9, _vm$task9$assignee, _vm$task10, _vm$task10$observer, _vm$task10$observer$d, _vm$task11, _vm$task11$observer, _vm$task12, _vm$task12$team, _vm$task13, _vm$task13$team, _vm$task14, _vm$task14$team, _vm$task15, _vm$task16, _vm$task17, _vm$task17$phase, _vm$task17$phase$data, _vm$task18, _vm$task18$phase, _vm$task18$phase$data, _vm$task19, _vm$task20, _vm$task21, _vm$task22, _vm$task23, _vm$task24, _vm$task25, _vm$task26, _vm$task27, _vm$task28, _vm$task29, _vm$task30, _vm$task31, _vm$task32, _vm$task33, _vm$task34;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [[_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "8",
      "xs": "12"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "10",
      "lg": "10",
      "xs": "12"
    }
  }, [_c('b-badge', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.top",
      value: 'Board Name',
      expression: "'Board Name'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    staticClass: "mb-2",
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s((_vm$task = _vm.task) === null || _vm$task === void 0 ? void 0 : (_vm$task$milestoneTas = _vm$task.milestoneTaskBoard) === null || _vm$task$milestoneTas === void 0 ? void 0 : (_vm$task$milestoneTas2 = _vm$task$milestoneTas.data) === null || _vm$task$milestoneTas2 === void 0 ? void 0 : _vm$task$milestoneTas2.title) + " ")]), (_vm$task2 = _vm.task) !== null && _vm$task2 !== void 0 && _vm$task2.title ? [_c('div', [_c('b-card-text', [_c('h2', [_vm._v(" " + _vm._s((_vm$task3 = _vm.task) === null || _vm$task3 === void 0 ? void 0 : _vm$task3.title) + " "), _c('feather-icon', {
    attrs: {
      "icon": "CopyIcon"
    },
    on: {
      "click": _vm.copyUrlToClipboard
    }
  })], 1), _vm.showCopiedMessage ? _c('span', {
    staticClass: "copied-message"
  }, [_c('b-badge', {
    staticClass: "badge-glow",
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" Link Copied! ")])], 1) : _vm._e()])], 1)] : _vm._e()], 2), _vm.$permissionAbility(_vm.MILESTONE_BOARD_TASK_EDIT, _vm.permissions) ? [_c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('span', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "btn btn-sm",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.showTaskModal(_vm.task);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "EditIcon"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Edit")])], 1)], 1)])] : _vm._e()], 2), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_vm.$permissionAbility(_vm.MILESTONE_BOARD_SUB_TASK_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "btn btn-sm mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showSubTaskForm
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "BriefcaseIcon"
    }
  }), _vm._v(" Add Sub Task ")], 1)] : _vm._e(), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "btn-sm",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showTaskLinkForm
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "LinkIcon"
    }
  }), _vm._v(" Link Task ")], 1)], 2)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [(_vm$task4 = _vm.task) !== null && _vm$task4 !== void 0 && _vm$task4.description ? [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Description:")]), _c('b-card-text', [_c('div', {
    domProps: {
      "innerHTML": _vm._s((_vm$task5 = _vm.task) === null || _vm$task5 === void 0 ? void 0 : _vm$task5.description)
    }
  })])] : _vm._e()], 2)]), _vm.isSubTaskFormLoaded ? [_c('b-col', {
    staticClass: "mt-1 mb-1",
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('validation-observer', {
    ref: "subTaskAdd"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.subTaskAddForm($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "8",
      "lg": "8",
      "xs": "12"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "sub task title",
      "vid": "title",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "title",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Sub Task Title"
          },
          model: {
            value: _vm.subTaskTitle,
            callback: function callback($$v) {
              _vm.subTaskTitle = $$v;
            },
            expression: "subTaskTitle"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1186396778)
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_vm.isSubTaskLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right btn-sm",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.closeSubTaskForm
    }
  }, [_vm._v(" Cancel ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right mr-1 btn-sm",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Add ")])]], 2)], 1)], 1)], 1)], 1)] : _vm._e(), _vm.subTaskList.length > 0 ? _c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('h5', [_vm._v(" " + _vm._s(_vm.subTaskList.length > 0 ? "Sub Task List:" : "") + " ")]), _c('div', {
    staticClass: "sequencial-sub-task-list"
  }, [_c('draggable', {
    staticClass: "list-group list-group-flush cursor-move",
    attrs: {
      "list": _vm.sequencetialSubTaskList,
      "tag": "ul"
    },
    on: {
      "end": _vm.onSequenceChange
    }
  }, _vm._l(_vm.sequencetialSubTaskList, function (subTask, index) {
    var _vm$nextTask, _subTask$phase, _subTask$phase$data, _subTask$phase2, _subTask$phase2$data;
    return _c('b-list-group-item', {
      key: index,
      staticClass: "d-flex justify-content-between align-items-center",
      attrs: {
        "tag": "li"
      }
    }, [_c('b-link', {
      on: {
        "click": function click($event) {
          var _vm$task6, _vm$task6$milestoneTa, _vm$task6$milestoneTa2;
          return _vm.onShowSubTask((_vm$task6 = _vm.task) === null || _vm$task6 === void 0 ? void 0 : (_vm$task6$milestoneTa = _vm$task6.milestoneTaskBoard) === null || _vm$task6$milestoneTa === void 0 ? void 0 : (_vm$task6$milestoneTa2 = _vm$task6$milestoneTa.data) === null || _vm$task6$milestoneTa2 === void 0 ? void 0 : _vm$task6$milestoneTa2.id, _vm.task.id, _vm.task.short_title, subTask === null || subTask === void 0 ? void 0 : subTask.id, subTask === null || subTask === void 0 ? void 0 : subTask.short_title);
        }
      }
    }, [_c('b-badge', {
      attrs: {
        "variant": "light-warning"
      }
    }, [_vm._v(_vm._s(index + 1))]), _vm._v(" " + _vm._s(subTask === null || subTask === void 0 ? void 0 : subTask.title) + " ")], 1), _c('div', {
      staticClass: "custom-search d-flex align-items-center justify-content-end"
    }, [(subTask === null || subTask === void 0 ? void 0 : subTask.id) == ((_vm$nextTask = _vm.nextTask) === null || _vm$nextTask === void 0 ? void 0 : _vm$nextTask.id) ? _c('b-badge', {
      staticClass: "mr-1",
      attrs: {
        "variant": "light-danger"
      }
    }, [_vm._v(" Up Next ")]) : _vm._e(), subTask !== null && subTask !== void 0 && subTask.is_completed ? [_c('b-badge', {
      staticClass: "mr-1",
      attrs: {
        "variant": "light-success"
      }
    }, [_vm._v(_vm._s(subTask === null || subTask === void 0 ? void 0 : (_subTask$phase = subTask.phase) === null || _subTask$phase === void 0 ? void 0 : (_subTask$phase$data = _subTask$phase.data) === null || _subTask$phase$data === void 0 ? void 0 : _subTask$phase$data.title))])] : [_c('b-badge', {
      staticClass: "mr-1",
      attrs: {
        "variant": "light-primary"
      }
    }, [_vm._v(_vm._s(subTask === null || subTask === void 0 ? void 0 : (_subTask$phase2 = subTask.phase) === null || _subTask$phase2 === void 0 ? void 0 : (_subTask$phase2$data = _subTask$phase2.data) === null || _subTask$phase2$data === void 0 ? void 0 : _subTask$phase2$data.title))])], _vm.$permissionAbility(_vm.MILESTONE_BOARD_SUB_TASK_EDIT, _vm.permissions) ? [_c('b-dropdown', {
      staticClass: "mr-1 btn-sm",
      staticStyle: {
        "padding": "0"
      },
      attrs: {
        "no-caret": "",
        "variant": subTask !== null && subTask !== void 0 && subTask.sequence_num ? 'outline-warning' : 'outline-primary',
        "text": subTask !== null && subTask !== void 0 && subTask.sequence_num ? 'Sequential' : 'General',
        "disabled": subTask === null || subTask === void 0 ? void 0 : subTask.is_completed
      }
    }, [_c('b-dropdown-item', {
      on: {
        "click": function click($event) {
          return _vm.resetToGeneral(subTask === null || subTask === void 0 ? void 0 : subTask.id);
        }
      }
    }, [_vm._v(" Reset ")])], 1)] : _vm._e(), _vm.$permissionAbility(_vm.MILESTONE_BOARD_SUB_TASK_DELETE, _vm.permissions) ? [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "'rgba(255, 255, 255, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "btn-sm",
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.onDeleteSubTask(subTask === null || subTask === void 0 ? void 0 : subTask.id);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "TrashIcon"
      }
    })], 1)] : _vm._e()], 2)], 1);
  }), 1)], 1), _c('b-list-group', [_c('br'), _vm._l(_vm.generalSubTaskList, function (subTask) {
    var _subTask$phase3, _subTask$phase3$data, _subTask$phase4, _subTask$phase4$data;
    return _c('b-list-group-item', {
      key: subTask === null || subTask === void 0 ? void 0 : subTask.id,
      staticClass: "d-flex justify-content-between align-items-center",
      attrs: {
        "tag": "li"
      }
    }, [_c('b-link', {
      on: {
        "click": function click($event) {
          var _vm$task7, _vm$task7$milestoneTa, _vm$task7$milestoneTa2;
          return _vm.onShowSubTask((_vm$task7 = _vm.task) === null || _vm$task7 === void 0 ? void 0 : (_vm$task7$milestoneTa = _vm$task7.milestoneTaskBoard) === null || _vm$task7$milestoneTa === void 0 ? void 0 : (_vm$task7$milestoneTa2 = _vm$task7$milestoneTa.data) === null || _vm$task7$milestoneTa2 === void 0 ? void 0 : _vm$task7$milestoneTa2.id, _vm.task.id, _vm.task.short_title, subTask === null || subTask === void 0 ? void 0 : subTask.id, subTask === null || subTask === void 0 ? void 0 : subTask.short_title);
        }
      }
    }, [_vm._v(" " + _vm._s(subTask === null || subTask === void 0 ? void 0 : subTask.title) + " "), (subTask === null || subTask === void 0 ? void 0 : subTask.sequence_num) > 0 ? [_c('b-badge', {
      staticClass: "ml-1",
      attrs: {
        "variant": "light-warning"
      }
    }, [_vm._v(" " + _vm._s(subTask === null || subTask === void 0 ? void 0 : subTask.sequence_num) + " ")])] : _vm._e()], 2), _c('div', {
      staticClass: "custom-search d-flex align-items-center justify-content-end"
    }, [subTask !== null && subTask !== void 0 && subTask.is_completed ? [_c('b-badge', {
      staticClass: "mr-1",
      attrs: {
        "variant": "light-success"
      }
    }, [_vm._v(_vm._s(subTask === null || subTask === void 0 ? void 0 : (_subTask$phase3 = subTask.phase) === null || _subTask$phase3 === void 0 ? void 0 : (_subTask$phase3$data = _subTask$phase3.data) === null || _subTask$phase3$data === void 0 ? void 0 : _subTask$phase3$data.title))])] : [_c('b-badge', {
      staticClass: "mr-1",
      attrs: {
        "variant": "light-primary"
      }
    }, [_vm._v(_vm._s(subTask === null || subTask === void 0 ? void 0 : (_subTask$phase4 = subTask.phase) === null || _subTask$phase4 === void 0 ? void 0 : (_subTask$phase4$data = _subTask$phase4.data) === null || _subTask$phase4$data === void 0 ? void 0 : _subTask$phase4$data.title))])], _vm.$permissionAbility(_vm.MILESTONE_BOARD_SUB_TASK_EDIT, _vm.permissions) ? [_c('b-dropdown', {
      staticClass: "mr-1 btn-sm",
      staticStyle: {
        "padding": "0"
      },
      attrs: {
        "no-caret": "",
        "variant": subTask !== null && subTask !== void 0 && subTask.sequence_num ? 'outline-warning' : 'outline-primary',
        "text": subTask !== null && subTask !== void 0 && subTask.sequence_num ? 'Sequential' : 'General',
        "disabled": subTask === null || subTask === void 0 ? void 0 : subTask.is_completed
      }
    }, [[_c('b-dropdown-item', {
      on: {
        "click": function click($event) {
          return _vm.updateSequence(subTask);
        }
      }
    }, [_vm._v("Set As Sequential ")])]], 2)] : _vm._e(), _vm.$permissionAbility(_vm.MILESTONE_BOARD_SUB_TASK_DELETE, _vm.permissions) ? [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "'rgba(255, 255, 255, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "btn-sm",
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.onDeleteSubTask(subTask === null || subTask === void 0 ? void 0 : subTask.id);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "TrashIcon"
      }
    })], 1)] : _vm._e()], 2)], 1);
  })], 2)], 1) : _vm._e(), _vm.isTaskLinkFormLoaded ? [_c('b-col', {
    staticClass: "mt-1 mb-1",
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('validation-observer', {
    ref: "taskLinkAdd"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.taskLinkAddForm($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "8",
      "lg": "8",
      "xs": "12"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "task title",
      "vid": "task_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "sub_task_title",
            "placeholder": "Search Task Here",
            "options": _vm.tasksIdOption,
            "label": "name"
          },
          model: {
            value: _vm.task_id,
            callback: function callback($$v) {
              _vm.task_id = $$v;
            },
            expression: "task_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 462558587)
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_vm.isTaskLinkLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right btn-sm",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.closeTaskLinkForm
    }
  }, [_vm._v(" Cancel ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right mr-1 btn-sm",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Add ")])]], 2)], 1)], 1)], 1)], 1)] : _vm._e(), _vm.taskLinkList.length > 0 ? _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('h5', [_vm._v(" " + _vm._s(_vm.taskLinkList.length > 0 ? "Linked Task List:" : "") + " ")]), _c('b-list-group', _vm._l(_vm.taskLinkList, function (taskList) {
    return _c('b-list-group-item', {
      key: taskList === null || taskList === void 0 ? void 0 : taskList.id,
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('b-link', {
      on: {
        "click": function click($event) {
          return _vm.onShowTask(taskList === null || taskList === void 0 ? void 0 : taskList.task_subtask_id);
        }
      }
    }, [_vm._v(" " + _vm._s(taskList === null || taskList === void 0 ? void 0 : taskList.task_subtask_title) + " ")]), _c('div', {
      staticClass: "custom-search d-flex align-items-center justify-content-end"
    }, [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "'rgba(255, 255, 255, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "btn-sm",
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.onDeleteLinkTask(taskList === null || taskList === void 0 ? void 0 : taskList.id);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "XCircleIcon"
      }
    })], 1)], 1)], 1);
  }), 1)], 1) : _vm._e()], 2), _c('b-row', [_vm.taskDetailsPreviousFiles.length > 0 ? [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Attachment:")]), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', [_c('div', {
    staticClass: "scrollable-file-container"
  }, [_c('div', {
    staticClass: "uploaded-files mb-2 scrollable-file-content"
  }, _vm._l(_vm.taskDetailsPreviousFiles, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "file-preview"
    }, [_c('b-row', {
      staticClass: "mr-1"
    }, [_c('b-col', {
      attrs: {
        "md": "8",
        "lg": "8",
        "xs": "12"
      }
    }, [_c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.bottom",
        value: item.file_name,
        expression: "\n                                        item.file_name\n                                      ",
        modifiers: {
          "hover": true,
          "bottom": true
        }
      }]
    }, [_vm.isPreviousFileImage(item) ? [_c('img', {
      attrs: {
        "src": item === null || item === void 0 ? void 0 : item.original_url,
        "alt": "Preview",
        "width": "100",
        "height": "100"
      }
    })] : [_c('svg', {
      attrs: {
        "width": "100px",
        "height": "100px",
        "viewBox": "0 0 24 24",
        "xmlns": "http://www.w3.org/2000/svg",
        "fill": "#000000"
      }
    }, [_c('g', {
      attrs: {
        "id": "SVGRepo_bgCarrier",
        "stroke-width": "0"
      }
    }), _c('g', {
      attrs: {
        "id": "SVGRepo_tracerCarrier",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('g', {
      attrs: {
        "id": "SVGRepo_iconCarrier"
      }
    }, [_c('title'), _c('g', {
      attrs: {
        "id": "Complete"
      }
    }, [_c('g', {
      attrs: {
        "id": "F-File"
      }
    }, [_c('g', {
      attrs: {
        "id": "Text"
      }
    }, [_c('g', [_c('path', {
      attrs: {
        "d": "M18,22H6a2,2,0,0,1-2-2V4A2,2,0,0,1,6,2h7.1a2,2,0,0,1,1.5.6l4.9,5.2A2,2,0,0,1,20,9.2V20A2,2,0,0,1,18,22Z",
        "fill": "none",
        "id": "File",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "7.9",
        "x2": "16.1",
        "y1": "17.5",
        "y2": "17.5"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "7.9",
        "x2": "16.1",
        "y1": "13.5",
        "y2": "13.5"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "8",
        "x2": "13",
        "y1": "9.5",
        "y2": "9.5"
      }
    })])])])])])])]], 2)]), _c('b-col', {
      attrs: {
        "md": "3",
        "lg": "3",
        "xs": "12"
      }
    }, [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "\n                                        'rgba(255, 255, 255, 0.15)'\n                                      ",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "flex-shrink-0 btn-sm",
      attrs: {
        "variant": "outline-primary"
      },
      on: {
        "click": function click($event) {
          return _vm.onShowImage(item);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "EyeIcon"
      }
    })], 1)], 1)], 1)], 1);
  }), 0)])])], 1)] : _vm._e()], 2)], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-tabs', [_c('b-tab', {
    attrs: {
      "title": "Comments"
    }
  }, [_c('div', {
    staticClass: "comment-scrollable",
    attrs: {
      "active": ""
    }
  }, [_c('b-card-text', [_c('b-row', [_c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "md": "12",
      "xl": "12"
    }
  }, [_vm.isShowCommentModal == false ? [_c('div', {
    staticClass: "mx-2 my-1"
  }, [_c('b-form-input', {
    staticClass: "custom-font",
    attrs: {
      "id": "type",
      "type": "text",
      "placeholder": "Add Comment....",
      "autocomplete": "off"
    },
    on: {
      "click": _vm.showCommentModal
    }
  })], 1)] : _vm._e(), _vm.taskEditClicked == false && _vm.isShowCommentModal == true ? [_c('div', {
    staticClass: "mt-1 mx-2"
  }, [_c('validation-observer', {
    ref: "addCommentFormValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.addCommentForm($event);
      }
    }
  }, [_c('b-form-group', [_c('ValidationProvider', {
    attrs: {
      "name": "description",
      "vid": "description",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('div', {
          class: {
            fullscreen: _vm.isFullscreen
          }
        }, [_c('quill-editor', {
          staticClass: "custom-quill-editor",
          style: {
            width: '98% !important'
          },
          attrs: {
            "options": _vm.editorOption
          },
          model: {
            value: _vm.commentDescription,
            callback: function callback($$v) {
              _vm.commentDescription = $$v;
            },
            expression: "commentDescription"
          }
        }, [_c('div', {
          attrs: {
            "slot": "toolbar",
            "id": "toolbar"
          },
          slot: "toolbar"
        }, [_c('span', {
          staticClass: "ql-formats"
        }, [_c('button', {
          staticClass: "ql-bold"
        }, [_vm._v(" Bold ")]), _c('button', {
          staticClass: "ql-italic"
        }, [_vm._v(" Italic ")]), _c('button', {
          staticClass: "ql-underline"
        }, [_vm._v(" Underline ")]), _c('button', {
          staticClass: "ql-strike"
        }, [_vm._v(" Strike ")]), _c('button', {
          staticClass: "ql-blockquote"
        }), _c('button', {
          staticClass: "ql-list",
          attrs: {
            "value": "ordered"
          }
        }), _c('button', {
          staticClass: "ql-list",
          attrs: {
            "value": "bullet"
          }
        }), _c('button', {
          staticClass: "ql-script",
          attrs: {
            "value": "sub"
          }
        }), _c('button', {
          staticClass: "ql-script",
          attrs: {
            "value": "super"
          }
        }), _c('button', {
          staticClass: "ql-indent",
          attrs: {
            "value": "-1"
          }
        }), _c('button', {
          staticClass: "ql-indent",
          attrs: {
            "value": "+1"
          }
        }), _c('button', {
          staticClass: "ql-direction",
          attrs: {
            "value": "rtl"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": ""
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "center"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "right"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "justify"
          }
        }), _c('select', {
          staticClass: "ql-color"
        }), _c('select', {
          staticClass: "ql-background"
        }), _c('select', {
          staticClass: "ql-size"
        }, [_c('option', {
          attrs: {
            "value": "small"
          }
        }), _c('option', {
          attrs: {
            "selected": ""
          }
        }), _c('option', {
          attrs: {
            "value": "large"
          }
        }), _c('option', {
          attrs: {
            "value": "huge"
          }
        })]), _c('select', {
          staticClass: "ql-font"
        }), _c('select', {
          staticClass: "ql-header"
        }, [_c('option', {
          attrs: {
            "value": "1"
          }
        }, [_vm._v(" Heading 1 ")]), _c('option', {
          attrs: {
            "value": "2"
          }
        }, [_vm._v(" Heading 2 ")]), _c('option', {
          attrs: {
            "value": "3"
          }
        }, [_vm._v(" Heading 3 ")]), _c('option', {
          attrs: {
            "value": "4"
          }
        }, [_vm._v(" Heading 4 ")]), _c('option', {
          attrs: {
            "value": "5"
          }
        }, [_vm._v(" Heading 5 ")]), _c('option', {
          attrs: {
            "value": "6"
          }
        }, [_vm._v(" Heading 6 ")]), _c('option', {
          attrs: {
            "selected": ""
          }
        }, [_vm._v("Normal")])]), _c('button', {
          staticClass: "ql-link"
        }), _c('button', {
          staticClass: "fullscreen-btn",
          attrs: {
            "type": "button",
            "title": _vm.isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'
          },
          on: {
            "click": _vm.toggleFullscreen
          }
        }, [_vm._v(" 🔳 ")])])])])], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 855675035)
  })], 1), _vm.isLoading ? [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "size": "sm",
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm._v(" Post Comment ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.hiddenCommentModal
    }
  }, [_vm._v(" Cancel ")])]], 2)], 1)], 1)] : _vm._e(), _vm._l(_vm.commentList, function (comment, index) {
    var _comment$createdBy, _comment$createdBy$da, _comment$createdBy2, _comment$createdBy2$d, _vm$authUser, _comment$createdBy3, _comment$createdBy3$d, _vm$authUser2, _comment$createdBy4, _comment$createdBy4$d;
    return _c('div', {
      key: index,
      staticClass: "d-flex align-items-start mx-2 my-1"
    }, [_c('b-avatar', {
      staticClass: "mt-25 mr-75",
      attrs: {
        "src": comment === null || comment === void 0 ? void 0 : (_comment$createdBy = comment.createdBy) === null || _comment$createdBy === void 0 ? void 0 : (_comment$createdBy$da = _comment$createdBy.data) === null || _comment$createdBy$da === void 0 ? void 0 : _comment$createdBy$da.avatar,
        "size": "34"
      }
    }), _c('div', {
      staticClass: "profile-user-info w-100"
    }, [_c('div', {
      staticClass: "d-flex align-items-center justify-content-between"
    }, [_c('h6', {
      staticClass: "mb-0"
    }, [_vm._v(" " + _vm._s(comment === null || comment === void 0 ? void 0 : (_comment$createdBy2 = comment.createdBy) === null || _comment$createdBy2 === void 0 ? void 0 : (_comment$createdBy2$d = _comment$createdBy2.data) === null || _comment$createdBy2$d === void 0 ? void 0 : _comment$createdBy2$d.name) + " ")]), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s(_vm.formatDateTime(comment === null || comment === void 0 ? void 0 : comment.created_at)))])]), _c('div', [_vm.isShowEditCommentModal == true && _vm.editCommentModalIndex == index ? [_c('div', {
      staticClass: "mt-1"
    }, [_c('validation-observer', {
      ref: "editCommentFormValidation",
      refInFor: true
    }, [_c('b-form', {
      on: {
        "submit": function submit($event) {
          $event.preventDefault();
          return _vm.editCommentForm($event);
        }
      }
    }, [_c('b-form-group', [_c('ValidationProvider', {
      attrs: {
        "name": "description",
        "vid": "description",
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref4) {
          var errors = _ref4.errors;
          return [_c('div', {
            class: {
              fullscreen: _vm.isFullscreen
            }
          }, [_c('quill-editor', {
            staticClass: "custom-quill-editor",
            style: {
              width: '98% !important'
            },
            attrs: {
              "options": _vm.editorOption
            },
            model: {
              value: _vm.commentDescription,
              callback: function callback($$v) {
                _vm.commentDescription = $$v;
              },
              expression: "commentDescription"
            }
          }, [_c('div', {
            attrs: {
              "slot": "toolbar",
              "id": "toolbar"
            },
            slot: "toolbar"
          }, [_c('span', {
            staticClass: "ql-formats"
          }, [_c('button', {
            staticClass: "ql-bold"
          }, [_vm._v(" Bold ")]), _c('button', {
            staticClass: "ql-italic"
          }, [_vm._v(" Italic ")]), _c('button', {
            staticClass: "ql-underline"
          }, [_vm._v(" Underline ")]), _c('button', {
            staticClass: "ql-strike"
          }, [_vm._v(" Strike ")]), _c('button', {
            staticClass: "ql-blockquote"
          }), _c('button', {
            staticClass: "ql-list",
            attrs: {
              "value": "ordered"
            }
          }), _c('button', {
            staticClass: "ql-list",
            attrs: {
              "value": "bullet"
            }
          }), _c('button', {
            staticClass: "ql-script",
            attrs: {
              "value": "sub"
            }
          }), _c('button', {
            staticClass: "ql-script",
            attrs: {
              "value": "super"
            }
          }), _c('button', {
            staticClass: "ql-indent",
            attrs: {
              "value": "-1"
            }
          }), _c('button', {
            staticClass: "ql-indent",
            attrs: {
              "value": "+1"
            }
          }), _c('button', {
            staticClass: "ql-direction",
            attrs: {
              "value": "rtl"
            }
          }), _c('button', {
            staticClass: "ql-align",
            attrs: {
              "value": ""
            }
          }), _c('button', {
            staticClass: "ql-align",
            attrs: {
              "value": "center"
            }
          }), _c('button', {
            staticClass: "ql-align",
            attrs: {
              "value": "right"
            }
          }), _c('button', {
            staticClass: "ql-align",
            attrs: {
              "value": "justify"
            }
          }), _c('select', {
            staticClass: "ql-color"
          }), _c('select', {
            staticClass: "ql-background"
          }), _c('select', {
            staticClass: "ql-size"
          }, [_c('option', {
            attrs: {
              "value": "small"
            }
          }), _c('option', {
            attrs: {
              "selected": ""
            }
          }), _c('option', {
            attrs: {
              "value": "large"
            }
          }), _c('option', {
            attrs: {
              "value": "huge"
            }
          })]), _c('select', {
            staticClass: "ql-font"
          }), _c('select', {
            staticClass: "ql-header"
          }, [_c('option', {
            attrs: {
              "value": "1"
            }
          }, [_vm._v(" Heading 1 ")]), _c('option', {
            attrs: {
              "value": "2"
            }
          }, [_vm._v(" Heading 2 ")]), _c('option', {
            attrs: {
              "value": "3"
            }
          }, [_vm._v(" Heading 3 ")]), _c('option', {
            attrs: {
              "value": "4"
            }
          }, [_vm._v(" Heading 4 ")]), _c('option', {
            attrs: {
              "value": "5"
            }
          }, [_vm._v(" Heading 5 ")]), _c('option', {
            attrs: {
              "value": "6"
            }
          }, [_vm._v(" Heading 6 ")]), _c('option', {
            attrs: {
              "selected": ""
            }
          }, [_vm._v("Normal")])]), _c('button', {
            staticClass: "ql-link"
          }), _c('button', {
            staticClass: "fullscreen-btn",
            attrs: {
              "type": "button",
              "title": _vm.isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'
            },
            on: {
              "click": _vm.toggleFullscreen
            }
          }, [_vm._v(" 🔳 ")])])])])], 1), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1), _vm.isLoading ? [_c('b-button', {
      attrs: {
        "size": "sm",
        "variant": "primary",
        "disabled": ""
      }
    }, [_c('b-spinner', {
      attrs: {
        "small": ""
      }
    }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "\n                                            'rgba(255, 255, 255, 0.15)'\n                                          ",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "mr-1",
      attrs: {
        "size": "sm",
        "variant": "primary",
        "type": "submit"
      }
    }, [_vm._v(" Submit ")]), _c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "\n                                            'rgba(255, 255, 255, 0.15)'\n                                          ",
        modifiers: {
          "400": true
        }
      }],
      attrs: {
        "size": "sm",
        "variant": "primary"
      },
      on: {
        "click": _vm.hiddenEditCommentModal
      }
    }, [_vm._v(" Cancel ")])]], 2)], 1)], 1)] : [_c('small', {
      domProps: {
        "innerHTML": _vm._s(comment === null || comment === void 0 ? void 0 : comment.description)
      }
    }), _c('small', [_c('span', {
      staticClass: "mr-1"
    }, [((_vm$authUser = _vm.authUser) === null || _vm$authUser === void 0 ? void 0 : _vm$authUser.id) == (comment === null || comment === void 0 ? void 0 : (_comment$createdBy3 = comment.createdBy) === null || _comment$createdBy3 === void 0 ? void 0 : (_comment$createdBy3$d = _comment$createdBy3.data) === null || _comment$createdBy3$d === void 0 ? void 0 : _comment$createdBy3$d.id) ? _c('b-link', {
      on: {
        "click": function click($event) {
          return _vm.onShowEditComment(index, comment);
        }
      }
    }, [_vm._v("Edit")]) : _vm._e()], 1), _c('span', {
      staticClass: "mr-1"
    }, [((_vm$authUser2 = _vm.authUser) === null || _vm$authUser2 === void 0 ? void 0 : _vm$authUser2.id) == (comment === null || comment === void 0 ? void 0 : (_comment$createdBy4 = comment.createdBy) === null || _comment$createdBy4 === void 0 ? void 0 : (_comment$createdBy4$d = _comment$createdBy4.data) === null || _comment$createdBy4$d === void 0 ? void 0 : _comment$createdBy4$d.id) ? _c('b-link', {
      on: {
        "click": function click($event) {
          return _vm.onCommentDelete(comment === null || comment === void 0 ? void 0 : comment.id);
        }
      }
    }, [_vm._v("Delete")]) : _vm._e()], 1)])]], 2)])], 1);
  })], 2)], 1)], 1)], 1)])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4",
      "xs": "12"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Assigner")]), _c('div', [(_vm$task8 = _vm.task) !== null && _vm$task8 !== void 0 && (_vm$task8$assignee = _vm$task8.assignee) !== null && _vm$task8$assignee !== void 0 && (_vm$task8$assignee$da = _vm$task8$assignee.data) !== null && _vm$task8$assignee$da !== void 0 && _vm$task8$assignee$da.name ? [_c('userCard', {
    attrs: {
      "user": (_vm$task9 = _vm.task) === null || _vm$task9 === void 0 ? void 0 : (_vm$task9$assignee = _vm$task9.assignee) === null || _vm$task9$assignee === void 0 ? void 0 : _vm$task9$assignee.data
    }
  })] : _c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v(" Not Assigned ")])], 1)], 2)]), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Observer")]), _c('div', [(_vm$task10 = _vm.task) !== null && _vm$task10 !== void 0 && (_vm$task10$observer = _vm$task10.observer) !== null && _vm$task10$observer !== void 0 && (_vm$task10$observer$d = _vm$task10$observer.data) !== null && _vm$task10$observer$d !== void 0 && _vm$task10$observer$d.name ? _c('b-card-text', [_c('userCard', {
    attrs: {
      "user": (_vm$task11 = _vm.task) === null || _vm$task11 === void 0 ? void 0 : (_vm$task11$observer = _vm$task11.observer) === null || _vm$task11$observer === void 0 ? void 0 : _vm$task11$observer.data
    }
  })], 1) : _c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v(" Not Assigned ")])], 1)], 1)]), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Assign User")]), _c('div', [_vm._l((_vm$task12 = _vm.task) === null || _vm$task12 === void 0 ? void 0 : (_vm$task12$team = _vm$task12.team) === null || _vm$task12$team === void 0 ? void 0 : _vm$task12$team.data, function (member, index) {
    return _c('span', {
      key: index
    }, [index <= 2 ? [_c('b-avatar', {
      attrs: {
        "src": (member === null || member === void 0 ? void 0 : member.avatar) === '' ? '/avatar.svg' : member === null || member === void 0 ? void 0 : member.avatar,
        "size": "26"
      }
    })] : _vm._e()], 2);
  }), ((_vm$task13 = _vm.task) === null || _vm$task13 === void 0 ? void 0 : (_vm$task13$team = _vm$task13.team) === null || _vm$task13$team === void 0 ? void 0 : _vm$task13$team.data.length) > 3 ? _c('span', [_vm._v(" + " + _vm._s(((_vm$task14 = _vm.task) === null || _vm$task14 === void 0 ? void 0 : (_vm$task14$team = _vm$task14.team) === null || _vm$task14$team === void 0 ? void 0 : _vm$task14$team.data.length) - 3) + " more ")]) : _vm._e(), _c('b-button', {
    staticClass: "btn-icon rounded-circle btn-sm ml-1",
    attrs: {
      "variant": "outline-primary",
      "disabled": _vm.$permissionAbility(_vm.MILESTONE_BOARD_TASK_EDIT, _vm.permissions) ? false : true
    },
    on: {
      "click": function click($event) {
        return _vm.showAddMemberModal(_vm.task);
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserPlusIcon"
    }
  })], 1)], 2)]), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Labels")]), ((_vm$task15 = _vm.task) === null || _vm$task15 === void 0 ? void 0 : _vm$task15.labels.data.length) > 0 ? [_c('b-card-text', _vm._l((_vm$task16 = _vm.task) === null || _vm$task16 === void 0 ? void 0 : _vm$task16.labels.data, function (label) {
    return _c('span', {
      key: label.id
    }, [_c('b-badge', {
      staticClass: "label-margin",
      attrs: {
        "variant": "light-primary"
      }
    }, [_vm._v(" " + _vm._s(label.name) + " ")])], 1);
  }), 0)] : [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v(" N/A ")])], 1)]], 2)]), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Phase")]), (_vm$task17 = _vm.task) !== null && _vm$task17 !== void 0 && (_vm$task17$phase = _vm$task17.phase) !== null && _vm$task17$phase !== void 0 && (_vm$task17$phase$data = _vm$task17$phase.data) !== null && _vm$task17$phase$data !== void 0 && _vm$task17$phase$data.title ? [_c('b-dropdown', {
    staticStyle: {
      "padding": "0"
    },
    attrs: {
      "no-caret": "",
      "variant": "outline-primary",
      "text": (_vm$task18 = _vm.task) === null || _vm$task18 === void 0 ? void 0 : (_vm$task18$phase = _vm$task18.phase) === null || _vm$task18$phase === void 0 ? void 0 : (_vm$task18$phase$data = _vm$task18$phase.data) === null || _vm$task18$phase$data === void 0 ? void 0 : _vm$task18$phase$data.title
    }
  }, [_vm._l(_vm.taskPhaseOptions, function (phase) {
    return _c('b-dropdown-item', {
      key: phase === null || phase === void 0 ? void 0 : phase.id,
      on: {
        "click": function click($event) {
          return _vm.updatePhase(phase);
        }
      }
    }, [_vm._v(" " + _vm._s(phase === null || phase === void 0 ? void 0 : phase.title) + " ")]);
  }), _c('b-dropdown-divider'), _c('b-dropdown-item', {
    on: {
      "click": _vm.onShowPhase
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusCircleIcon"
    }
  }), _vm._v(" Add More ")], 1)], 2)] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Priority")]), ((_vm$task19 = _vm.task) === null || _vm$task19 === void 0 ? void 0 : _vm$task19.priority) === 'high' ? [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v(" High ")])], 1)] : ((_vm$task20 = _vm.task) === null || _vm$task20 === void 0 ? void 0 : _vm$task20.priority) === 'low' ? [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" Low ")])], 1)] : ((_vm$task21 = _vm.task) === null || _vm$task21 === void 0 ? void 0 : _vm$task21.priority) === 'medium' ? [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-warning"
    }
  }, [_vm._v(" Medium ")])], 1)] : [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" Normal ")])], 1)]], 2)]), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Status")]), (_vm$task22 = _vm.task) !== null && _vm$task22 !== void 0 && _vm$task22.is_completed ? [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v(" Completed ")])], 1)] : [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v(" Not Completed ")])], 1)]], 2)]), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Estimation Point")]), (_vm$task23 = _vm.task) !== null && _vm$task23 !== void 0 && _vm$task23.point ? [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s((_vm$task24 = _vm.task) === null || _vm$task24 === void 0 ? void 0 : _vm$task24.point) + " ")])], 1)] : [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v(" N/A ")])], 1)]], 2)]), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Deadline")]), (_vm$task25 = _vm.task) !== null && _vm$task25 !== void 0 && _vm$task25.deadline ? [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.formatDate((_vm$task26 = _vm.task) === null || _vm$task26 === void 0 ? void 0 : _vm$task26.deadline)) + " ")])] : [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v(" N/A ")])], 1)]], 2)]), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Actual Start Date")]), (_vm$task27 = _vm.task) !== null && _vm$task27 !== void 0 && _vm$task27.actual_start_date ? [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.formatDate((_vm$task28 = _vm.task) === null || _vm$task28 === void 0 ? void 0 : _vm$task28.actual_start_date)) + " ")])] : [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v(" N/A ")])], 1)]], 2)]), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Actual End Date")]), (_vm$task29 = _vm.task) !== null && _vm$task29 !== void 0 && _vm$task29.actual_end_date ? [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.formatDate((_vm$task30 = _vm.task) === null || _vm$task30 === void 0 ? void 0 : _vm$task30.actual_end_date)) + " ")])] : [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-danger"
    }
  }, [_vm._v(" N/A ")])], 1)]], 2)]), (_vm$task31 = _vm.task) !== null && _vm$task31 !== void 0 && _vm$task31.wordcount ? _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Word Count")]), [_c('b-card-text', [_c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s((_vm$task32 = _vm.task) === null || _vm$task32 === void 0 ? void 0 : _vm$task32.wordcount) + " ")])], 1)]], 2)]) : _vm._e(), (_vm$task33 = _vm.task) !== null && _vm$task33 !== void 0 && _vm$task33.contentlink ? _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Content Link")]), (_vm$task34 = _vm.task) !== null && _vm$task34 !== void 0 && _vm$task34.contentlink ? [_c('b-card-text', [_c('b-badge', {
    staticClass: "badge-glow",
    attrs: {
      "variant": "primary"
    }
  }, [_c('a', {
    attrs: {
      "href": _vm.task.contentlink,
      "target": "_blank"
    }
  }, [_vm._v("View Content")])])], 1)] : _vm._e()], 2)]) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card-code', [_c('b-tabs', [_c('b-tab', {
    attrs: {
      "title": "Activity Log"
    }
  }, [_c('div', {
    staticClass: "log-scrollable"
  }, [_c('b-card', [_c('app-timeline', _vm._l(_vm.taskActivityLog, function (log, index) {
    return _c('app-timeline-item', {
      key: index,
      attrs: {
        "variant": "primary"
      }
    }, [_c('div', {
      staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
    }, [_c('h6', {
      domProps: {
        "innerHTML": _vm._s(_vm.formatLogTitle(log))
      }
    }), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s(_vm.formatDateTime(log === null || log === void 0 ? void 0 : log.created_at)))])]), _c('p', {
      domProps: {
        "innerHTML": _vm._s(_vm.formatLogDescription(log))
      }
    })]);
  }), 1)], 1)], 1)]), _c('b-tab', {
    attrs: {
      "title": "Phase Duration Log"
    },
    on: {
      "click": _vm.loadTimeDurationLog
    }
  }, [_c('div', {
    staticClass: "log-scrollable"
  }, [_c('b-card', [_vm.phaseChangeLog.length > 0 ? [_c('app-timeline', _vm._l(_vm.phaseChangeLog, function (log, index) {
    return _c('app-timeline-item', {
      key: index,
      attrs: {
        "variant": _vm.setLogVariant(log)
      }
    }, [_c('div', {
      staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
    }, [_c('h6', {
      domProps: {
        "innerHTML": _vm._s(_vm.formatPhaseLogTitle(log))
      }
    }), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s(_vm.formatDateTime(log === null || log === void 0 ? void 0 : log.created_at)))])]), _c('p', {
      domProps: {
        "innerHTML": _vm._s(_vm.formatPhaseLogDescription(log === null || log === void 0 ? void 0 : log.created_at, _vm.nextPhaseChangeStartTime[index]))
      }
    })]);
  }), 1)] : _vm._e()], 2)], 1)])], 1)], 1)], 1)], 1)], 1), _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-task-board-phase-task",
      "centered": "",
      "title": "Edit Task Information",
      "hide-footer": "",
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenTaskModal
    }
  }, [_c('validation-observer', {
    ref: "taskEdit"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.taskEditForm($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Title",
      "label-for": "title"
    }
  }), _vm.taskSuggestionLoading ? _c('div', {
    staticClass: "input-loader"
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  })], 1) : _vm._e(), _vm.isAiEnabled ? _c('div', {
    staticClass: "text-right suggestion-button-position"
  }, [_c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-primary",
      modifiers: {
        "hover": true,
        "v-primary": true
      }
    }],
    staticClass: "rounded-circle p-1 hover-effect button-icon",
    attrs: {
      "variant": "outline-primary",
      "size": "sm",
      "title": "Generate With AI"
    },
    on: {
      "click": function click($event) {
        return _vm.getSuggestion(_vm.title, 'title');
      }
    }
  }, [_c('svg', {
    staticClass: "icon icon-tabler icons-tabler-outline icon-tabler-sparkles",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "stroke": "none",
      "d": "M0 0h24v24H0z",
      "fill": "none"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 18a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm0 -12a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm-7 12a6 6 0 0 1 6 -6a6 6 0 0 1 -6 -6a6 6 0 0 1 -6 6a6 6 0 0 1 6 6z"
    }
  })])])], 1) : _vm._e()], 1), _c('ValidationProvider', {
    attrs: {
      "name": "title",
      "vid": "title",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-input-group', [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "title",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter task title"
          },
          model: {
            value: _vm.title,
            callback: function callback($$v) {
              _vm.title = $$v;
            },
            expression: "title"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type ",
      "label-for": "type"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "type",
      "vid": "type"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "type",
            "options": _vm.taskSubtaskTypeOptionConstant,
            "reduce": function reduce(option) {
              return option.value;
            },
            "placeholder": "Select task type",
            "label": "name"
          },
          model: {
            value: _vm.type,
            callback: function callback($$v) {
              _vm.type = $$v;
            },
            expression: "type"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Priority ",
      "label-for": "priority"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "priority",
      "vid": "priority"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "priority",
            "options": _vm.priorityOptionConstants,
            "reduce": function reduce(option) {
              return option.value;
            },
            "placeholder": "Select task priority",
            "label": "name"
          },
          model: {
            value: _vm.selectPriorityType,
            callback: function callback($$v) {
              _vm.selectPriorityType = $$v;
            },
            expression: "selectPriorityType"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Label",
      "label-for": "labels"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "labels",
      "vid": "labels"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "id": "labels",
            "placeholder": "Add Task Label",
            "label": "name",
            "multiple": "",
            "taggable": "",
            "push-tags": ""
          },
          model: {
            value: _vm.selectLabels,
            callback: function callback($$v) {
              _vm.selectLabels = $$v;
            },
            expression: "selectLabels"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Estimation Point",
      "label-for": "point"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "point",
      "vid": "point",
      "rules": "min_value:0|max_value:9999"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "point",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Estimation Point"
          },
          model: {
            value: _vm.point,
            callback: function callback($$v) {
              _vm.point = $$v;
            },
            expression: "point"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Observer",
      "label-for": "observer"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "observer",
      "vid": "observer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "observer",
            "options": _vm.milestoneBoardTeamMemberIdOptions,
            "reduce": function reduce(option) {
              return option.id;
            },
            "placeholder": "Select Observer",
            "label": "name"
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('UserSelect', {
                attrs: {
                  "user": data
                }
              })];
            }
          }], null, true),
          model: {
            value: _vm.selectObserver,
            callback: function callback($$v) {
              _vm.selectObserver = $$v;
            },
            expression: "selectObserver"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Assigner",
      "label-for": "assigner"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "assigner",
      "vid": "assigner"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "assigner",
            "options": _vm.allMemberIdOptions,
            "reduce": function reduce(option) {
              return option.id;
            },
            "placeholder": "Select assigner",
            "label": "name"
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('UserSelect', {
                attrs: {
                  "user": data
                }
              })];
            }
          }], null, true),
          model: {
            value: _vm.selectAssignee,
            callback: function callback($$v) {
              _vm.selectAssignee = $$v;
            },
            expression: "selectAssignee"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Description",
      "label-for": "description"
    }
  }), _vm.desSuggestionLoading ? _c('div', {
    staticClass: "input-loader-des"
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  })], 1) : _vm._e(), _vm.isAiEnabled ? _c('div', {
    staticClass: "text-right suggestion-button-position"
  }, [_c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-primary",
      modifiers: {
        "hover": true,
        "v-primary": true
      }
    }],
    staticClass: "rounded-circle p-1 hover-effect button-icon",
    attrs: {
      "variant": "outline-primary",
      "size": "sm",
      "title": "Generate With AI"
    },
    on: {
      "click": function click($event) {
        return _vm.getSuggestion(_vm.description, 'description');
      }
    }
  }, [_c('svg', {
    staticClass: "icon icon-tabler icons-tabler-outline icon-tabler-sparkles",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('path', {
    attrs: {
      "stroke": "none",
      "d": "M0 0h24v24H0z",
      "fill": "none"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 18a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm0 -12a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm-7 12a6 6 0 0 1 6 -6a6 6 0 0 1 -6 -6a6 6 0 0 1 -6 6a6 6 0 0 1 6 6z"
    }
  })])])], 1) : _vm._e()], 1), _c('ValidationProvider', {
    attrs: {
      "name": "description",
      "vid": "description"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var errors = _ref12.errors;
        return [_vm.taskEditClicked ? [_c('div', {
          class: {
            fullscreen: _vm.isFullscreen
          }
        }, [_c('quill-editor', {
          staticClass: "custom-quill-editor",
          style: {
            height: '13rem !important',
            paddingBottom: '5rem !important'
          },
          attrs: {
            "options": _vm.editorOption
          },
          model: {
            value: _vm.description,
            callback: function callback($$v) {
              _vm.description = $$v;
            },
            expression: "description"
          }
        }, [_c('div', {
          attrs: {
            "slot": "toolbar",
            "id": "toolbar"
          },
          slot: "toolbar"
        }, [_c('span', {
          staticClass: "ql-formats"
        }, [_c('button', {
          staticClass: "ql-bold"
        }, [_vm._v("Bold")]), _c('button', {
          staticClass: "ql-italic"
        }, [_vm._v("Italic")]), _c('button', {
          staticClass: "ql-underline"
        }, [_vm._v("Underline")]), _c('button', {
          staticClass: "ql-strike"
        }, [_vm._v("Strike")]), _c('button', {
          staticClass: "ql-blockquote"
        }), _c('button', {
          staticClass: "ql-list",
          attrs: {
            "value": "ordered"
          }
        }), _c('button', {
          staticClass: "ql-list",
          attrs: {
            "value": "bullet"
          }
        }), _c('button', {
          staticClass: "ql-script",
          attrs: {
            "value": "sub"
          }
        }), _c('button', {
          staticClass: "ql-script",
          attrs: {
            "value": "super"
          }
        }), _c('button', {
          staticClass: "ql-indent",
          attrs: {
            "value": "-1"
          }
        }), _c('button', {
          staticClass: "ql-indent",
          attrs: {
            "value": "+1"
          }
        }), _c('button', {
          staticClass: "ql-direction",
          attrs: {
            "value": "rtl"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": ""
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "center"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "right"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "justify"
          }
        }), _c('select', {
          staticClass: "ql-color"
        }), _c('select', {
          staticClass: "ql-background"
        }), _c('select', {
          staticClass: "ql-size"
        }, [_c('option', {
          attrs: {
            "value": "small"
          }
        }), _c('option', {
          attrs: {
            "selected": ""
          }
        }), _c('option', {
          attrs: {
            "value": "large"
          }
        }), _c('option', {
          attrs: {
            "value": "huge"
          }
        })]), _c('select', {
          staticClass: "ql-font"
        }), _c('select', {
          staticClass: "ql-header"
        }, [_c('option', {
          attrs: {
            "value": "1"
          }
        }, [_vm._v("Heading 1")]), _c('option', {
          attrs: {
            "value": "2"
          }
        }, [_vm._v("Heading 2")]), _c('option', {
          attrs: {
            "value": "3"
          }
        }, [_vm._v("Heading 3")]), _c('option', {
          attrs: {
            "value": "4"
          }
        }, [_vm._v("Heading 4")]), _c('option', {
          attrs: {
            "value": "5"
          }
        }, [_vm._v("Heading 5")]), _c('option', {
          attrs: {
            "value": "6"
          }
        }, [_vm._v("Heading 6")]), _c('option', {
          attrs: {
            "selected": ""
          }
        }, [_vm._v("Normal")])]), _c('button', {
          staticClass: "ql-link"
        }), _c('button', {
          staticClass: "fullscreen-btn",
          attrs: {
            "type": "button",
            "title": _vm.isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'
          },
          on: {
            "click": _vm.toggleFullscreen
          }
        }, [_vm._v(" 🔳 ")])])])])], 1)] : _vm._e(), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _vm.previousFiles.length > 0 ? [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', [_c('div', {
    staticClass: "scrollable-file-container"
  }, [_c('div', {
    staticClass: "uploaded-files mb-2 scrollable-file-content"
  }, _vm._l(_vm.previousFiles, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "file-preview"
    }, [_c('b-row', {
      staticClass: "mr-1"
    }, [_c('b-col', {
      attrs: {
        "md": "8",
        "lg": "8",
        "xs": "12"
      }
    }, [_c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.bottom",
        value: item.file_name,
        expression: "item.file_name",
        modifiers: {
          "hover": true,
          "bottom": true
        }
      }]
    }, [_vm.isPreviousFileImage(item) ? [_c('img', {
      attrs: {
        "src": item === null || item === void 0 ? void 0 : item.original_url,
        "alt": "Preview",
        "width": "100",
        "height": "100"
      }
    })] : [_c('svg', {
      attrs: {
        "width": "100px",
        "height": "100px",
        "viewBox": "0 0 24 24",
        "xmlns": "http://www.w3.org/2000/svg",
        "fill": "#000000"
      }
    }, [_c('g', {
      attrs: {
        "id": "SVGRepo_bgCarrier",
        "stroke-width": "0"
      }
    }), _c('g', {
      attrs: {
        "id": "SVGRepo_tracerCarrier",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('g', {
      attrs: {
        "id": "SVGRepo_iconCarrier"
      }
    }, [_c('title'), _c('g', {
      attrs: {
        "id": "Complete"
      }
    }, [_c('g', {
      attrs: {
        "id": "F-File"
      }
    }, [_c('g', {
      attrs: {
        "id": "Text"
      }
    }, [_c('g', [_c('path', {
      attrs: {
        "d": "M18,22H6a2,2,0,0,1-2-2V4A2,2,0,0,1,6,2h7.1a2,2,0,0,1,1.5.6l4.9,5.2A2,2,0,0,1,20,9.2V20A2,2,0,0,1,18,22Z",
        "fill": "none",
        "id": "File",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "7.9",
        "x2": "16.1",
        "y1": "17.5",
        "y2": "17.5"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "7.9",
        "x2": "16.1",
        "y1": "13.5",
        "y2": "13.5"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "8",
        "x2": "13",
        "y1": "9.5",
        "y2": "9.5"
      }
    })])])])])])])]], 2)]), _c('b-col', {
      attrs: {
        "md": "3",
        "lg": "3",
        "xs": "12"
      }
    }, [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "'rgba(255, 255, 255, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "flex-shrink-0 btn-sm",
      attrs: {
        "variant": "outline-danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removePreviousFile(index);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "XIcon"
      }
    })], 1), _c('br'), _c('br'), _c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "'rgba(255, 255, 255, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "flex-shrink-0 btn-sm",
      attrs: {
        "variant": "outline-primary"
      },
      on: {
        "click": function click($event) {
          return _vm.onShowImage(item);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "EyeIcon"
      }
    })], 1)], 1)], 1)], 1);
  }), 0)])])], 1)] : _vm._e(), _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "file-input"
  }, [_c('label', {
    staticClass: "attach-icon",
    attrs: {
      "for": "file-upload"
    }
  }, [_vm._v(" Attach File "), _c('feather-icon', {
    staticClass: "mr-50",
    staticStyle: {
      "color": "#7367f0"
    },
    attrs: {
      "icon": "PaperclipIcon"
    }
  })], 1), _c('input', {
    attrs: {
      "hidden": "",
      "id": "file-upload",
      "type": "file",
      "multiple": ""
    },
    on: {
      "change": _vm.handleFileUpload
    }
  })])])], 1), _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "scrollable-file-container"
  }, [_c('div', {
    staticClass: "uploaded-files mb-2 scrollable-file-content"
  }, _vm._l(_vm.uploadedFiles, function (file, index) {
    return _c('div', {
      key: index,
      staticClass: "file-preview"
    }, [_c('b-row', {
      staticClass: "mr-1"
    }, [_c('b-col', {
      attrs: {
        "md": "8",
        "lg": "8",
        "xs": "12"
      }
    }, [_c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.bottom",
        value: file.name,
        expression: "file.name",
        modifiers: {
          "hover": true,
          "bottom": true
        }
      }]
    }, [_vm.isImage(file) ? [_c('img', {
      attrs: {
        "src": file.preview,
        "alt": "Preview",
        "width": "100",
        "height": "100"
      }
    })] : [_c('svg', {
      attrs: {
        "width": "100px",
        "height": "100px",
        "viewBox": "0 0 24 24",
        "xmlns": "http://www.w3.org/2000/svg",
        "fill": "#000000"
      }
    }, [_c('g', {
      attrs: {
        "id": "SVGRepo_bgCarrier",
        "stroke-width": "0"
      }
    }), _c('g', {
      attrs: {
        "id": "SVGRepo_tracerCarrier",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('g', {
      attrs: {
        "id": "SVGRepo_iconCarrier"
      }
    }, [_c('title'), _c('g', {
      attrs: {
        "id": "Complete"
      }
    }, [_c('g', {
      attrs: {
        "id": "F-File"
      }
    }, [_c('g', {
      attrs: {
        "id": "Text"
      }
    }, [_c('g', [_c('path', {
      attrs: {
        "d": "M18,22H6a2,2,0,0,1-2-2V4A2,2,0,0,1,6,2h7.1a2,2,0,0,1,1.5.6l4.9,5.2A2,2,0,0,1,20,9.2V20A2,2,0,0,1,18,22Z",
        "fill": "none",
        "id": "File",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "7.9",
        "x2": "16.1",
        "y1": "17.5",
        "y2": "17.5"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "7.9",
        "x2": "16.1",
        "y1": "13.5",
        "y2": "13.5"
      }
    }), _c('line', {
      attrs: {
        "fill": "none",
        "stroke": "#000000",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": "2",
        "x1": "8",
        "x2": "13",
        "y1": "9.5",
        "y2": "9.5"
      }
    })])])])])])])]], 2)]), _c('b-col', {
      attrs: {
        "md": "3",
        "lg": "3",
        "xs": "12"
      }
    }, [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "'rgba(255, 255, 255, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "flex-shrink-0 btn-sm",
      attrs: {
        "variant": "outline-danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeFile(index);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "XIcon"
      }
    })], 1)], 1)], 1)], 1);
  }), 0)])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Deadline",
      "label-for": "deadline"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "deadline",
      "vid": "deadline"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var errors = _ref13.errors;
        return [_c('b-form-datepicker', {
          staticClass: "form-control custom-font",
          attrs: {
            "id": "deadline",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "today-button": "",
            "close-button": "",
            "reset-button": "",
            "placeholder": "Deadline"
          },
          model: {
            value: _vm.deadline,
            callback: function callback($$v) {
              _vm.deadline = $$v;
            },
            expression: "deadline"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Actual Start Date",
      "label-for": "actual_start_date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "actual_start_date",
      "vid": "actual_start_date"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var errors = _ref14.errors;
        return [_c('b-form-datepicker', {
          staticClass: "form-control custom-font",
          attrs: {
            "id": "actual_start_date",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "today-button": "",
            "close-button": "",
            "reset-button": "",
            "placeholder": "Actual Start Date "
          },
          model: {
            value: _vm.actualStartDate,
            callback: function callback($$v) {
              _vm.actualStartDate = $$v;
            },
            expression: "actualStartDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Actual End Date",
      "label-for": "actual_end_date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "actual_end_date",
      "vid": "actual_end_date"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var errors = _ref15.errors;
        return [_c('b-form-datepicker', {
          staticClass: "form-control custom-font",
          attrs: {
            "id": "actual_end_date",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "today-button": "",
            "close-button": "",
            "reset-button": "",
            "placeholder": "Actual End Date "
          },
          model: {
            value: _vm.actualEndDate,
            callback: function callback($$v) {
              _vm.actualEndDate = $$v;
            },
            expression: "actualEndDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Word Count",
      "label-for": "wordcount"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "wordcount",
      "vid": "wordcount"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref16) {
        var errors = _ref16.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "wordcount",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter task Content Wordcount",
            "disabled": _vm.sub_tasks_sum_wordcount > 0
          },
          model: {
            value: _vm.wordcount,
            callback: function callback($$v) {
              _vm.wordcount = $$v;
            },
            expression: "wordcount"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), this.sub_tasks_sum_wordcount > 0 ? _c('small', {
    staticClass: "text-danger"
  }, [_vm._v("Task word count not available when sub task has word count")]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Content Link",
      "label-for": "contentlink"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "contentlink",
      "vid": "contentlink"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var errors = _ref17.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "contentlink",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter task Content Link"
          },
          model: {
            value: _vm.contentlink,
            callback: function callback($$v) {
              _vm.contentlink = $$v;
            },
            expression: "contentlink"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _vm.isLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right mt-2",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1), _c('div', [_c('b-modal', {
    attrs: {
      "id": "file-preview-modal",
      "centered": "",
      "title": "File Preview",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenFilePreviewModal
    }
  }, [[_c('b-img', {
    attrs: {
      "src": _vm.previewFile,
      "fluid-grow": "",
      "alt": "Fluid-grow image"
    }
  }), _c('p', {
    staticClass: "text-center"
  }, [_vm._v(" Open in new "), _c('b-link', {
    attrs: {
      "href": _vm.previewFile,
      "target": "_blank"
    }
  }, [_vm._v("window")])], 1)]], 2)], 1), _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-add-member-form",
      "centered": "",
      "title": "Add Member",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenAddMemberModal
    }
  }, [_c('validation-observer', {
    ref: "addMemberValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.addMemberValidationForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Member",
      "label-for": "user_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "user_id",
      "vid": "user_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref18) {
        var errors = _ref18.errors;
        return [_c('v-select', {
          attrs: {
            "id": "user_id",
            "placeholder": "Search by employee name or email",
            "filterable": false,
            "options": _vm.allMemberIdOptions,
            "reduce": function reduce(item) {
              return item.id;
            },
            "label": "name",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "multiple": ""
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('UserSelect', {
                attrs: {
                  "user": data
                }
              })];
            }
          }], null, true),
          model: {
            value: _vm.selectMemberIds,
            callback: function callback($$v) {
              _vm.selectMemberIds = $$v;
            },
            expression: "selectMemberIds"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Add ")])]], 2)], 1)], 1)], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }